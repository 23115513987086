import React from "react"
import Layout from "../../components/layout"
import ForgotPassword from "../../components/security/forgotPassword"

const ForgotPasswordPage = () => {
  return (
    <Layout>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ForgotPassword></ForgotPassword>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ForgotPasswordPage
