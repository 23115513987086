import React from "react"
import { Auth } from "aws-amplify"

class ForgotPassword extends React.Component {
  state = {
    username: ``,
    password: ``,
    error: ``,
    authCode: "",
    stage: 0,
    passwordForgotStage: 0,
  }

  resetState = async () => {
    this.setState({ username: "" })
    this.setState({ password: "" })
    this.setState({ error: "" })
    this.setState({ authCode: "" })
    this.setState({ stage: 0 })
    this.setState({ passwordForgotStage: 0 })
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  forgotPassword = async () => {
    const { username } = this.state
    try {
      if (!username) {
        return
      }

      await Auth.forgotPassword(username)
      this.setState({ passwordForgotStage: 1 })
    } catch (err) {
      this.setState({ error: this.translateErrorMessage(err) })
      console.log("error forgot password...", err)
    }
  }

  confirmForgotPassword = async () => {
    const { username, authCode, password } = this.state
    try {
      await Auth.forgotPasswordSubmit(username, authCode, password)
      this.resetState()
      navigate("/")
    } catch (err) {
      this.setState({ error: this.translateErrorMessage(err) })
      console.log("error forgot password...", err)
    }
  }

  translateErrorMessage(error) {
    switch (error.code) {
      case "NotAuthorizedException":
        return "اسم المستخدم أو كلمة المرور غير صحيحة"
      case "UsernameExistsException":
        return "حساب مع البريد الإلكتروني المحدد موجود بالفعل"
      case "CodeMismatchException":
        return "تم توفير رمز التحقق غير صالح ، يرجى المحاولة مرة أخرى"
      default:
        return error.message
    }
  }

  render() {
    return (
      <>
        <div className="forgot-form">
          {this.state.passwordForgotStage === 0 && (
            <>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="البريد الإلكتروني"
                  name="username"
                  onChange={this.handleUpdate}
                  value={this.state.username}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-envelope" />
                  </span>
                </div>
              </div>
              <div
                className="form-errors forgot-errors"
                hidden={this.state.error == null || this.state.error == ""}
              >
                <span>{this.state.error}</span>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.forgotPassword}
              >
                إستعادة كلمة المرور
              </button>
            </>
          )}
          {this.state.passwordForgotStage === 1 && (
            <>
              <div className="input-group mb-3">
                <input
                  type="text"
                  id="registrationEmail"
                  className="form-control"
                  placeholder="رمز التحقق"
                  name="authCode"
                  onChange={this.handleUpdate}
                  value={this.state.authCode}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-envelope" />
                  </span>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="كلمة السر"
                  name="password"
                  onChange={this.handleUpdate}
                  value={this.state.password}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-lock" />
                  </span>
                </div>
              </div>
              <div
                className="form-errors forgot-errors"
                hidden={this.state.error == null || this.state.error == ""}
              >
                <span>{this.state.error}</span>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.confirmForgotPassword}
              >
                تأكيد كلمة المرور الجديدة
              </button>
            </>
          )}
        </div>
      </>
    )
  }
}

export default ForgotPassword
